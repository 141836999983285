import React, { useCallback, useEffect, useRef, useState } from "react";
import { EvCardList } from "../components/EvCardList/EvCardList";

import Smartcar from "@smartcar/auth";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth/useAuth";
import { sendAccessToken, addUnsupportedEV, CarPollingSource, sendAccessCode } from "../apis/energyNetApi";
import getEnv from "../env";
import { trackError } from "../apis/tracking";
import { BackButton } from "../components/Shared/BackButton";
import Modal from "../components/Shared/Modal";
import { Button } from "../components/Shared/Button";
import useBrandConfig from "../hooks/useBrandConfig";
import useLocalStorageState from "use-local-storage-state";
import { useDispatch } from 'react-redux';
import { updateCars } from '../store/carSlice';
import { useTrackEvent } from "../hooks/useTrackEvent/useTrackEvent";
import allCarMakes, { Make } from "../helpers/allCarMakes";
import { Spinner } from "../components/Shared/Spinner";
import { t } from "i18next";
import { MulticolorHeadline } from "../components/Shared/MulticolorHeadline";
import { getLoginUrlForBrowser } from "../apis/teslaFleetApi";
import { useVerifyCode } from "../hooks/useVerifyCode";
import { QueryString } from "../constants/queryString";
import { useAddVehicleUrl } from "../hooks/useAddVehicleUrl/useAddVehicleUrl";
import UnsupportedEVModal from "../components/Shared/UnsupportedEVModal";

const smartcarClientId = getEnv().SMARTCAR_CLIENT_ID;
const smartcarRedirectUri = getEnv().SMARTCAR_REDIRECT;
const envTestMode = getEnv().SMARTCAR_TEST_MODE;

export const AddVehicle = () => {
  const { user, email } = useAuth();
  const navigate = useNavigate();
  const [waiting, setWaiting] = useState(false);
  const [unsupportedEvMake, setUnsupportedEvMake] = useState("");
  const errorModalButtonRef = useRef<HTMLButtonElement>(null);
  const otherModalInputButtonRef = useRef<HTMLButtonElement>(null);
  const otherModalThanksButtonRef = useRef<HTMLButtonElement>(null);
  const otherModalExistsRef = useRef<HTMLButtonElement>(null);
  const config = useBrandConfig();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const trackEvent = useTrackEvent();
  const env = getEnv().ENVIRONMENT;
  const [reconnectMake, setReconnectMake] = useState<Make | undefined>(undefined);
  const [verifier, setVerifyCode] = useVerifyCode();
  const [simulatedLocal] = useLocalStorageState<boolean | null>(QueryString.Simulated, { defaultValue: null });

  const location = useLocation();
  const [teslaRedirectUrl] = useState(location.state?.redirectUrl);

  // if ?simulated is in the url or in local storage, allow smartcar simulated cars
  const simulated = searchParams.get(QueryString.Simulated) !== null || simulatedLocal === true;
  const testMode = config.testMode || envTestMode || simulated;
  const fromDashboard = searchParams.get(QueryString.SignUp) === null;

  const teslaFleet = config.flagTeslaFleet || searchParams.get(QueryString.Tesla) === "fleet";
  const newConnector = config.flagNewConnector;

  // we might redirect to the evision domain
  const reconnectQueryString = searchParams.get(QueryString.Reconnect);
  const addVehicleUrl = useAddVehicleUrl(user, !fromDashboard, reconnectQueryString);

  // if we're on the evision domain, when we leave we need to get back to the program domain we came from    
  const getReturnUrl = useCallback(() => {
    let returnUrl = "";
    if (window.location.hostname === "evision.flexcharging.com") {
      // need to get back to the brand domain
      switch (env) {
        case "production":
          returnUrl = `https://${config.programName}.flexcharging.com`;
          break;
        case "development":
          if (window.location.protocol === "https:") {
            returnUrl = `https://${config.programName}.flexcharging.com`;
          }
          else {
            returnUrl = `https://localhost:${window.location.port}`;
          }
          break;
      }
    }

    return returnUrl;
  }, [config.programName, env, window.location.protocol]);

  const goToDashboard = useCallback(() => {

    let returnUrl = getReturnUrl();
    if (fromDashboard) {
      // clear the cars so they reload when we return to dashboard
      dispatch(updateCars(undefined));

      // user is signed up and addition additional vehicle

      // check if the current domain is evision.flexcharging.com
      if (returnUrl) {
        // need to get back to the brand domain
        window.location.href = returnUrl + "/dashboard";
      }
      else {
        navigate("/dashboard", { replace: true });
      }
    }
    else {
      // user is in the signup flow
      if (returnUrl) {
        // need to get back to the brand domain
        window.location.href = returnUrl + "/vehicleadded";
      } else {
        navigate("/vehicleadded", { replace: true });
      }
    }
  }, [navigate, dispatch, getReturnUrl, fromDashboard]);

  const goToLocationInstructions = useCallback(() => {

    let returnUrl = getReturnUrl();

    if (returnUrl)
      window.location.href = returnUrl + "/locationinstructions";
    else {
      navigate("/locationinstructions", { replace: true });
    }

  }, [navigate, getReturnUrl]);

  // trick to call tesla sendAccessToken once in StrictMode across mounts
  const initialized = useRef(false)

  // Native Tesla callback handling
  useEffect(() => {
    // This token request should happen exactly once when the user is redirected back from tesla auth
    // This will add the tesla cars to the users account
    if (teslaRedirectUrl && verifier && !initialized.current) {
      initialized.current = true;

      // check the url for an error
      const url = new URL(teslaRedirectUrl);
      const error = url.searchParams.get("error");
      const state = url.searchParams.get("state");

      if (error) {
        setWaiting(false);
        if (error === "login_cancelled") return;

        trackEvent("TeslaError", { error });
        errorModalButtonRef.current?.click();
        return;
      }

      if (user) {
        setVerifyCode("");
        setWaiting(true);
        (newConnector ?
          sendAccessCode(user, "", "TESLA", teslaRedirectUrl, !testMode, verifier, CarPollingSource.TeslaFleet) :
          sendAccessToken(user, "", "TESLA", teslaRedirectUrl, !testMode, verifier, CarPollingSource.TeslaFleet))
          .then(() => {
            trackEvent("SendAccessTokenSuccess", { teslaRedirectUrl });

            if (state === "signup") {
              // this is a signup flow - go to location instructions
              goToLocationInstructions();
            }
            else {
              goToDashboard();
            }
            setWaiting(false);
          })
          .catch((error) => {
            trackEvent(newConnector ? "SendAuthCode" : "SendAccessTokenError", { teslaRedirectUrl, fleet: true });
            setWaiting(false);
            errorModalButtonRef.current?.click();
            trackError("Error connecting to Tesla", error);
          });
      }
    }
  }, [goToDashboard, setVerifyCode, teslaRedirectUrl, testMode, user, verifier, trackEvent, newConnector]);



  const openSmartcarDialog = useCallback((make: string,) => {
    const onComplete = (err: any, code: string, state?: string, virtualKeyUrl?: string) => {
      if (user && email) {
        setWaiting(true)
        if (err) {
          trackEvent("SmartCarError", { make, error: err, code });
          setWaiting(false);
          errorModalButtonRef.current?.click();
          return;
        } else {
          trackEvent("SmartCarSuccess", { make, code });
        }
        (newConnector ?
          sendAccessCode(user, code, make, getEnv().SMARTCAR_REDIRECT, !testMode, "", CarPollingSource.SmartCar) :
          sendAccessToken(user, code, make, getEnv().SMARTCAR_REDIRECT, !testMode, "", CarPollingSource.SmartCar))
          .then((data) => {
            trackEvent("SendAccessTokenSuccess", { make, code });
            if (reconnectMake) {
              trackEvent("ReconnectSuccess", { make: reconnectMake.name });
            }

            // open a new browser window with with virtualKeyUrl
            if (virtualKeyUrl) {
              // open a separate popup window with the virtual key url
              // putting tesla on the url works more reliably on a device with the tesla app? tested, doesn't pop up app
              //var betterDeepLink = isMobile() ? virtualKeyUrl.replace("https", "tesla") : virtualKeyUrl;
              try {
                window.open(virtualKeyUrl, "_blank", "width=720,height=1200");
              } catch (error) {
                // popup blocker?
                console.log(error);
              }
            }

            goToDashboard();
            setWaiting(false);
          })
          .catch((error) => {
            trackEvent(newConnector ? "SendAuthCodeError" : "SendAccessTokenError", { make, code });
            setWaiting(false);
            errorModalButtonRef.current?.click();
            trackError("Error connecting to Smartcar", error);
          });
      }
    };

    const teslaMake = make === "TESLA";

    // @ts-ignore
    const smartcar = new Smartcar({
      clientId: smartcarClientId,
      redirectUri: smartcarRedirectUri,
      scope: [
        "required:read_vehicle_info",
        "required:read_battery",
        "required:read_charge",
        "required:read_location",
        "read_odometer",
        "required:read_vin",
        "control_charge",
        "read_charge_events",
        "read_charge_records",
        "read_charge_locations",
        "read_extended_vehicle_info"
      ],
      mode: (testMode && !teslaMake) ? "simulated" : "live",
      //flags: [],
      onComplete: onComplete,
    });

    const flags = [];
    if (config.defaultCountryCode !== "US") {
      flags.push("country:" + config.defaultCountryCode.toUpperCase());
    }
    if (teslaMake) {
      flags.push("tesla_auth:true");
    }

    trackEvent(teslaMake ? "SmartCarTeslaStarted" : "SmartCarStarted", { make });
    smartcar.openDialog({
      forcePrompt: true,
      vehicleInfo: { make },
      flags,
    });
  }, [testMode, trackEvent, user, email, goToDashboard, reconnectMake, config.defaultCountryCode, newConnector]);


  const openTeslaDialog = useCallback(async () => {
    const { url, codeVerifier } = await getLoginUrlForBrowser(fromDashboard ? "" : "signup"); // 
    // need to save this verifier so we can use it to trade for an access token
    trackEvent("TeslaStarted");
    setVerifyCode(codeVerifier);
    window.location.href = url;
  }, [setVerifyCode, trackEvent]);

  // "reconnect" query string param is used to do a reconnect to a certain make
  useEffect(() => {
    if (!email) {
      return;
    }

    const autoShowMake = searchParams.get(QueryString.Reconnect);
    if (!autoShowMake) {
      return;
    }
    const make = allCarMakes.find((make) => make.value === autoShowMake?.toUpperCase());

    if (!make) {
      if (fromDashboard) {
        goToDashboard();
      }
      return;
    }

    if (make && make?.value !== reconnectMake?.value) {
      setReconnectMake(make);
      trackEvent("ReconnectMakePageViewed", { make: make.value });
    }
  }, [searchParams, openSmartcarDialog, fromDashboard, goToDashboard, reconnectMake, email, trackEvent]);

  const openOtherDialog = () => {
    otherModalInputButtonRef.current?.click();
  }

  const evList = allCarMakes
    .filter(make => (!config.addVehicleOnlyShowManaged || make.managed) && (!config.addVehicleHiddenMakes.includes(make.value)))
    .filter(make => !make.europe || config.defaultCountry === "Ireland")
    .map(make => {
      if (make.value === "TESLA" && teslaFleet) {
        return { manufacturer: make.name, logo: make.logo, onClick: () => openTeslaDialog() };
      }
      else if (make.value === "OTHER") {
        return { manufacturer: make.name, logo: make.logo, onClick: () => openOtherDialog() };
      } else {
        return { manufacturer: make.name, logo: make.logo, onClick: () => openSmartcarDialog(make.value) };
      }
    });

  const evMakeExists = (make: string) => {
    // check the filtered list to see if this exists
    return (evList.find((ev) => ev.manufacturer.toLowerCase() === make.trim().toLowerCase()))
  }

  const handleAddOtherVehicle = (userMake: string, userModel: string) => {
    setUnsupportedEvMake(`${userMake} ${userModel}`);
    if (user && email) {

      if (evMakeExists(userMake)) {
        otherModalExistsRef.current?.click();
        return;
      }

      setWaiting(true);
      trackEvent("UnsupportedEVAdded", { make: userMake, model: userModel });
      addUnsupportedEV(user, {
        make: userMake,
        email: email,
      })
        .then(() => {
          setWaiting(false);
          otherModalThanksButtonRef.current?.click();
        })
        .catch((error) => {
          setWaiting(false);
          errorModalButtonRef.current?.click();
          trackError("Error adding new EV make.", error);
        });
    }

    handleAddOtherThanks();
  };

  const handleAddOtherThanks = () => {
    if (fromDashboard) {
      // user is signed up and addition additional vehicle
      goToDashboard();
    }
    else {
      // user is in the signup flow - skip the success page
      goToLocationInstructions();
    }
  };

  const handleBackClick = () => {
    goToDashboard();
  };

  const handleConfirmError = () => {
    if (fromDashboard) {
      goToDashboard();
    }
  };

  const handleReconnectButtonClick = () => {
    if (reconnectMake) {
      trackEvent("ReconnectButtonPressed", { make: reconnectMake.name });
      openSmartcarDialog(reconnectMake.value)
    }
  }

  // check if we need to redirect to the evision domain and go there
  if (addVehicleUrl && addVehicleUrl.redirect) {
    window.location.replace(addVehicleUrl.url);
    return null;
  }

  return (
    <div className={"mb-[20px]"}>
      {reconnectMake ?
        <>
          <MulticolorHeadline
            firstSentence={t("We need you to")}
            secondSentence={t("reconnect your vehicle")}
            breakBetween
          />
          <p className={"text-lg mt-[40px] md:mt-[20px] mb-[40px]"}>
            {t("reconnect EV text", { make: reconnectMake?.name })}
          </p>
          <div>
            <div className="flex justify-center pb-[20px]">
              <Button variant="primary" text={t("Reconnect Vehicle Now")} onClick={handleReconnectButtonClick} />
            </div>
            <div className="flex justify-center">
              <Button variant="secondary" noBorder text={t("Not Now")} data-te-target="reconnect-later" />
            </div>
          </div>
        </>
        :
        <>
          {fromDashboard &&
            <BackButton text={reconnectMake ? t("Dashboard") : t("Back")} onClick={handleBackClick} />
          }
          <h2 className={"text-4xl md:text-5xl font-sans-bold"}>
            {config.addVehicleHeader}
          </h2>
          <p className={"text-lg mt-[40px] md:mt-[20px]"}>
            {config.addVehicleInstructions}
          </p>
          <hr className={"border-default mt-[20px] mb-[10px]"} />
          {!email ?
            <div className="h-[300px]">
              <Spinner size="lg" />
            </div>
            :
            <EvCardList evList={evList} loading={waiting} />
          }
        </>
      }

      <Modal id="error-modal" label={t("Add Vehicle")} openButtonRef={errorModalButtonRef}>
        <div className={"flex flex-col items-center text-center"}>
          <div className={"text-lg mt-[20px]"}>{t("There was an unknown error adding your vehicle.")}</div>
          <div className={"flex justify-between mt-[30px] mb-[20px] w-[135px]"} data-te-modal-dismiss>
            <Button text={t("OK")} variant="primary" testId="ok-button-error" pill fullWidth onClick={handleConfirmError} />
          </div>
        </div>
      </Modal>

      <Modal id="reconnect-later" label="" cancelButton>
        <div className={"flex flex-col items-center text-center"}>
          <div className={"text-lg mt-[20px]"}>{t("reconnect later text")}</div>
          <div className={"flex justify-between mt-[30px] mb-[20px] w-[135px]"} data-te-modal-dismiss>
            <Button text={t("OK")} variant="primary" testId="ok-button-reconnect-later" pill fullWidth onClick={goToDashboard} />
          </div>
        </div>
      </Modal>

      <Modal id="make-exists-modal" label={t("Add Vehicle")} openButtonRef={otherModalExistsRef}>
        <div className={"flex flex-col items-center text-center"}>
          <div className={"text-lg mt-[20px]"}>{t("supported EV message", { unsupportedEvMake: unsupportedEvMake })}</div>
          <div className={"flex justify-between mt-[30px] mb-[20px] w-[135px]"} data-te-modal-dismiss>
            <Button text={t("OK")} variant="primary" testId="ok-button-exists" pill fullWidth onClick={() => { }} />
          </div>
        </div>
      </Modal>

      <UnsupportedEVModal openButtonRef={otherModalInputButtonRef} handleAddOtherVehicle={handleAddOtherVehicle} />

    </div>
  );
};
